window.addEventListener('turbolinks:load', () => {
  (function() {
    $('.custom-navbar #invoicemenu').on('click', function(e){
      $(this).next('div').toggle();
      $('.custom-navbar #invoicerequestmenu').next('div').hide();
      e.stopPropagation();
      e.preventDefault();
    });
    $('.custom-navbar #invoicerequestmenu').on("click", function(e){
      $(this).next('div').toggle();
      $('.custom-navbar #invoicemenu').next('div').hide();
      e.stopPropagation();
      e.preventDefault();
    });
    $('.custom-navbar #accounting').on("click", function(e){
      $('.custom-navbar #invoicemenu').next('div').hide();
      $('.custom-navbar #invoicerequestmenu').next('div').hide();
    });
    $(window).click(function (event) {
      if (!$(event.target).is('.custom-navbar *')) {
        $('.navbar-collapse.show').removeClass('show');
      }
    });
  })();
});
