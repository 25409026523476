import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'

window.addEventListener('turbolinks:load', () => {
  if ($('#invoice_is_b2b').is(":checked")) {
   $('#b2b_select').removeClass('hidden')
   $('#b2b_recipient_select').removeClass('hidden')
  }

  $(document).on('change','#invoice_is_b2b',function(){
    if ($('#invoice_is_b2b').is(":checked")) {
      $('#b2b_select').removeClass('hidden')
      $('#b2b_recipient_select').removeClass('hidden')
    } else {
      $('#b2b_select').addClass('hidden')
      $('#b2b_recipient_select').addClass('hidden')
    }
  })

  $('.select2').select2({
    width: '100%',
    ajax: {
      url: '/web/invoices/companies_searchable',
      dataType: 'json',
      data: function(params) {
        var selectedSearchable = $('#invoice_select_searchable').find("option").filter(":selected").text();
        return { search_content: params.term, selected_searchable: selectedSearchable }
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: data.results,
          pagination: {
            more: (params.page * 10) < data.total_count
          }
        };
      },
      cache: true
    },
    placeholder: 'Search for a company',
    minimumInputLength: 1,
    templateResult: formatResult,
    templateSelection: formatSelection,
  }).trigger('change')

  function formatResult(station) {
    return station.name;
  }

  function formatSelection(station) {
    return station.text || station.name;
  }
})
