import consumer from "./consumer"

$(function() {
  $('[data-channel-subscribe="queries"]').each(function(index, element) {
    var $element = $(element);
    var user_id = $element.data('user-id');
    var queryTemplate = $('[data-role="query-template"]');

    $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)

    consumer.subscriptions.create(
      {
        channel: "QueriesChannel",
        user: user_id
      },
      {
        received: function(data) {
          var content = queryTemplate.children().clone(true, true);
          content.find('[data-role="query-input"]').text(data.input);
          content.find('[data-role="query-output"]').text(data.output);
          content.find('[data-role="query-date"]').text(data.updated_at);
          $element.append(content);
          $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000);
        }
      }
    );
  });
});
